import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Button from "./button";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { messages } from "../i18n-translations";

interface FooterProps {
  cn?: boolean;
}

const Footer: React.FC<FooterProps> = ({ cn }) => {
  const breakpoints = useBreakpoint();
  const isScreenLg = breakpoints.lg;
  const isScreenMd = breakpoints.md;
  const lang = cn ? "cn" : "en";

  const siteMap = (
    <div className="flex justify-between px-6 pb-8 text-sm lg:px-0 lg:py-12 lg:flex-1">
      <div className="flex flex-col ">
        <div className="mb-4 text-base">{messages[lang].company}</div>
        <AnchorLink
          to={cn ? "/cn/" : "/"}
          title="Home"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].home}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/about#profile" : "/about#profile"}
          title="Jintai Profile"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].jintaiProfile}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/#partners" : "/#partners"}
          title="Machining"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].valuablePartnersAndCustomers}</span>
        </AnchorLink>
      </div>

      <div className="flex flex-col">
        <div className="mb-4 text-base">{messages[lang].ourServices}</div>
        <AnchorLink
          to={cn ? "/cn/services#machining" : "/services#machining"}
          title="Machining"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].machining}</span>
        </AnchorLink>
        <AnchorLink
          to={
            cn ? "/cn/services#plasticInjection" : "/services#plasticInjection"
          }
          title="Plastic Injection"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].plasticInjection}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/services#brazing" : "/services#brazing"}
          title="Brazing"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].brazing}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/services#casting" : "/services#casting"}
          title="Casting"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].casting}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/services#assembly" : "/services#assembly"}
          title="Assembly"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].assembly}</span>
        </AnchorLink>
        <AnchorLink
          to={
            cn ? "/cn/services#qualityAssurance" : "/services#qualityAssurance"
          }
          title="Quality Assurance"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].qualityAssurance}</span>
        </AnchorLink>
      </div>
      <div className="flex flex-col">
        <div className="mb-4 text-base">{messages[lang].resources}</div>
        <AnchorLink
          to={cn ? "/cn/resources#companyProfile" : "/resources#companyProfile"}
          title="Company Profile"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].companyProfile}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/resources#materialList" : "/resources#materialList"}
          title="Material List"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].materialList}</span>
        </AnchorLink>
      </div>
      <div className="flex flex-col">
        <div className="mb-4 text-base">{messages[lang].contactUs}</div>
        <AnchorLink
          to={cn ? "/cn/contact#location" : "/contact#location"}
          title="Company Location"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].companyLocation}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/contact#location" : "/contact#location"}
          title="Contact Information"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].contactInformation}</span>
        </AnchorLink>
        <AnchorLink
          to={cn ? "/cn/contact#form" : "/contact#form"}
          title="Contact Form"
          stripHash
          className="mb-2"
        >
          <span>{messages[lang].sendUsAMessage}</span>
        </AnchorLink>
      </div>
    </div>
  );
  return (
    <div className="relative flex flex-col flex-1 w-full bg-white">
      <div className="flex flex-col items-center px-8 py-10 xl:container xl:mx-auto md:flex-row lg:px-20 ">
        <div className="flex flex-col items-center md:flex-row md:w-1/2 md:flex-1 ">
          <StaticImage
            src="../images/jintai-logo.png"
            alt=""
            className="w-16"
          />
          <div className="flex flex-col items-center md:ml-2 lg:ml-6 lg:flex-row">
            <div
              className={`relative mt-4 mb-6 font-bold text-blue-700 uppercase md:mb-0 md:mt-0 lg:pr-6 ${
                cn ? "text-2xl" : " text-4xl"
              }`}
              style={{ fontFamily: `"dongle", sans-serif` }}
            >
              <span className="mt-3">{messages[lang].jintai}</span>
              <div className="absolute right-0 hidden border-l-2 top-1 h-5/6 lg:block" />
            </div>
            <div className="text-lg md:leading-tight lg:pl-6">
              {messages[lang].rubberAndPlastics}，
            </div>
            <div className="mb-10 text-lg md:mb-0 lg:pl-1">
              {messages[lang].precisionMachiningAndAssembly}
            </div>
          </div>
        </div>
        <div className="w-full md:w-4/12">
          <AnchorLink
            to={cn ? "/cn/contact#form" : "/contact#form"}
            title="Contact Form"
            stripHash
          >
            <Button big className="w-full py-6 text-xl md:py-4">
              {messages[lang].getAQuote}
            </Button>
          </AnchorLink>
        </div>
      </div>
      <div className="items-center hidden px-20 py-2 bg-white lg:flex xl:container xl:mx-auto">
        <div className="w-full py-2 border-t-2" />
      </div>
      {!isScreenLg ? (
        <div className="bg-white ">
          <div className="px-8 py-12 text-center md:text-left lg:px-20">
            <div className="text-sm">{messages[lang].aboutOurCompany}</div>
            <div className="pt-3 text-xs">{messages[lang].footerText}</div>
          </div>
          {isScreenMd && siteMap}
          <div className="z-30 flex items-center justify-center px-6 py-5 text-sm text-white bg-black md:text-base">
            {messages[lang].footerCopyright}
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-wrap gap-20 px-20 bg-white xl:container xl:mx-auto">
            <div className="w-3/12 py-12 text-left">
              <div className="text-sm">{messages[lang].aboutOurCompany}</div>
              <div className="pt-3 text-xs">{messages[lang].footerText}</div>
            </div>
            {siteMap}
          </div>
          <div className="z-30 flex justify-end w-full px-20 py-5 text-white bg-black">
            {messages[lang].footerCopyright}
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
