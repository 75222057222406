import React from "react";

import { ArrowRightIcon } from "@heroicons/react/solid";

interface ButtonProps {
  className?: string;
  arrow?: boolean;
  big?: boolean;
  submit?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  className,
  arrow,
  children,
  big,
  onClick,
  submit,
}) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      onClick={onClick}
      className={`flex justify-between items-center bg-blue-700 py-4 text-white ${
        className || ""
      }`}
    >
      <div className="flex-1 text-center">{children}</div>
      <ArrowRightIcon className={big ? "w-5 h-5 mr-7" : "w-4 h-4 mr-3"} />
    </button>
  );
};

export default Button;
