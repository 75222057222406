import React from "react";
import { Link } from "gatsby";
import "@fontsource/dongle/";

import MenuButton from "../components/menu-button";
import Button from "./button";
import Logo from "../images/jintai-logo.png";

import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { messages } from "../i18n-translations";

interface HeaderProps {
  isNavOpen: boolean;
  cn?: boolean;
  onMenuButtonPress: () => void;
}

const blueColor = "rgb(37, 99, 235)";
const Header: React.FC<HeaderProps> = ({
  isNavOpen,
  onMenuButtonPress,
  cn,
}) => {
  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const lang = cn ? "cn" : "en";
  const path =
    typeof window !== "undefined"
      ? window.location.pathname.replace("/cn", "")
      : "";

  return (
    <>
      <div className="flex justify-between w-full px-6 py-3 text-xs text-white bg-black lg:text-lg md:justify-end">
        <div className="md:mr-10">
          {messages[lang].phoneNumber}: +86 574 65292958
        </div>
        <div>{messages[lang].email}: info@jintai.com</div>
      </div>
      <div className="flex items-center justify-between px-6 py-4 lg:p-0">
        <div className="flex items-center flex-1">
          <Link to={cn ? "/cn" : "/"}>
            <img
              src={Logo}
              alt="jintai logo"
              className="w-10 lg:w-20 lg:w-10 lg:ml-4"
            />
          </Link>
          <Link to={cn ? "/cn" : "/"}>
            <div
              className={`ml-3 -mb-2 font-bold text-blue-700 uppercase lg:ml-4 ${
                cn ? "text-2xl" : " text-4xl"
              }`}
              style={{ fontFamily: `"dongle", sans-serif` }}
            >
              {messages[lang].jintai}
            </div>
          </Link>

          {isScreenXl && (
            <div className="flex">
              <Link
                to={cn ? "/cn" : "/"}
                className="ml-8 text-lg hover:text-blue-600"
                activeStyle={{ color: blueColor }}
              >
                {messages[lang].home}
              </Link>
              <Link
                to={cn ? "/cn/about" : "/about"}
                className="ml-8 text-lg hover:text-blue-600"
                activeStyle={{ color: blueColor }}
              >
                {messages[lang].aboutUs}
              </Link>
              <Link
                to={cn ? "/cn/services" : "/services"}
                className="ml-8 text-lg hover:text-blue-600"
                activeStyle={{ color: blueColor }}
              >
                {messages[lang].services}
              </Link>
              <Link
                to={cn ? "/cn/resources" : "/resources"}
                className="ml-8 text-lg hover:text-blue-600"
                activeStyle={{ color: blueColor }}
              >
                {messages[lang].resources}
              </Link>
              <Link
                to={cn ? "/cn/contact" : "/contact"}
                className="ml-8 text-lg hover:text-blue-600"
                activeStyle={{ color: blueColor }}
              >
                {messages[lang].contactUs}
              </Link>
            </div>
          )}
        </div>
        {!isScreenXl && (
          <div
            className="flex items-center justify-center w-12 h-12 text-white bg-blue-600 cursor-pointer"
            onClick={onMenuButtonPress}
          >
            <MenuButton isOpen={isNavOpen} />
          </div>
        )}
        {isScreenXl && (
          <div className="flex items-center justify-center">
            <Link
              className="mr-4 cursor-pointer hover:text-blue-600"
              to={cn ? path : `/cn${path}`}
            >
              {cn ? "EN" : "中文"}
            </Link>
            <AnchorLink
              to={cn ? "/cn/contact#form" : "/contact#form"}
              title="Get a Quote"
              stripHash
            >
              <Button className="w-56 text-lg">
                {messages[lang].getAQuote}
              </Button>
            </AnchorLink>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
