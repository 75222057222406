import React from "react";
import { Link } from "gatsby";
import { Item } from "framer-motion/types/components/Reorder/Item";
import { messages } from "../i18n-translations";

interface NavItem {
  path: string;
  name: string;
}
interface NavMenuProps {
  cn?: boolean;
}

const blueColor = "rgb(37, 99, 235)";

const NavMenu: React.FC<NavMenuProps> = ({ cn }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  const lang = cn ? "cn" : "en";
  const path =
    typeof window !== "undefined"
      ? window.location.pathname.replace("/cn", "")
      : "";

  let navItems: NavItem[] = [
    {
      path: cn ? "/cn" : "/",
      name: messages[lang].home,
    },
    {
      path: cn ? "/cn/about" : "/about",
      name: messages[lang].aboutUs,
    },
    {
      path: cn ? "/cn/services" : "/services",
      name: messages[lang].services,
    },
    {
      path: cn ? "/cn/resources" : "/resources",
      name: messages[lang].resources,
    },
    {
      path: cn ? "/cn/contact" : "/contact",
      name: messages[lang].contactUs,
    },
    {
      path: cn ? path : `/cn${path}`,
      name: cn ? "EN" : "中文",
    },
  ];

  return (
    <nav className="flex flex-col w-full text-white bg-black bg-opacity-95">
      <div className="flex flex-col items-center flex-1">
        {navItems.map((item, i) => {
          return (
            <Link
              key={item.name}
              to={item.path}
              activeStyle={{ color: blueColor }}
            >
              <div className={`px-4 py-2 text-3xl mt-10 hover:text-blue-600`}>
                {item.name}
              </div>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default NavMenu;
