const messages = {
  en: {
    aboutJintai: "About Jintai",
    aboutJintaiText:
      "For over 20 years Ningbo Jintai has been a key supplier for many Precision Machining, Rubber and Plastic Component parts. Striving to continue providing quality products at a competitive price, Jintai has taken innovative steps to ensure current machinery and production lines can maintain a competitive edge in such a highly competitive field. Providing custom made components and parts for many world-renowned companies, we have gained insight into many different production methods for products in fields such as Medical, Life Sciences, Automotive and even Financial Instruments. To ensure we can provide top quality parts for all fields, we're qualified under ISO 9001, IATF 16949 and ISO 13485.",
    aboutJintaiText2:
      "Jintai would gladly be your trustworthy partner in any custom made solution. Do let us know how we can help.",
    aboutOurCompany: "About Our Company",
    aboutUs: "About Us",
    assembly: "Assembly",
    assemblyDescription:
      "Integrating all our inhouse and outsourcing solutions to build simple and complex assembly products.",
    assemblyExperience: "Assembly experience",
    assemblyText1:
      "A combination of the above mentioned competency, we provide a one stop solution for all aspects involved in assembling the final product",
    assemblyText2:
      "We are able to fulfill any cleaning or testing requirements as specified by the customer",
    assemblyText3:
      "Products are assembled in our 100K clean room to ensure all quality standards are met before shipping out",
    brazing: "Brazing",
    brazingText1:
      "We offer a wide range of brazing solutions including: laser welding, TIG welding and most notably vacuum brazing",
    brazingText2:
      "Vacuum brazing allows for leak free brazing as well as allowing different materials to be weld together that cannot be achieved through conventional methods",
    brazingText3:
      "Commonly used in Aerospace and Medical products due to it's superior strength and smooth finish",
    casting: "Casting",
    castingSurfaceTreatmentSourcing: "Casting / Surface Treatment / Sourcing",
    castingSurfaceTreatmentSourcingDescription:
      "Close contact with many well-known suppliers enables us to provide customers with one-stop solutions.",
    castingText1:
      "We take great care in understanding our customer's needs in order to help lower supply chain related costs",
    castingText2:
      "We have a strong established supply chain for castings, surface finishing and purchasing, to provide a one stop solution for our customers",
    company: "Company",
    companyLocation: "Company Location",
    companyProfile: "Company Profile",
    contactInformation: "Contact Information",
    contactUs: "Contact Us",
    contactUsGeographicalAdvantage:
      "Geographical Advantage - Close proximity to many established vendors allowing us to provide a one stop solution for our customers.",
    continuousImprovement: "Continuous Improvement",
    continuousImprovementText:
      "Keeping up with top of the line manufacturing equipment, we can continue to build more complex products.",
    customerFocused: "Customer Focused",
    customerFocusedText:
      "Our expert team will help provide DFM solutions to find the most cost efficient method to help bring your design to life.",
    designForManufactureAnalysis: "Design for Manufacture Analysis",
    designForManufactureAnalysisText:
      "With your design, our expert team can help provide a DFM and help analyze areas which could directly help minimize cost whilst keeping the function intact. Please feel free to reach out to us.",
    downloadCompanyProfile: "Download Company Profile",
    downloadMaterialList: "Download Material List",
    easyAccessToPorts: "Easy Access to key logistic ports",
    easyAndSimpleSolutionText:
      "An easy and simple solution to help bring our customers designs to life.",
    email: "Email",
    emailAddress: "E-mail Address",
    fillInTheForm: "Fill in the form",
    fillInTheFormText:
      "Fill in the form and our representatives will contact you shortly!",
    footerCopyright: "© 2020 Ningbo Jintai Rubber & Plastic Co., Ltd",
    footerText:
      "For over 20 years Ningbo Jintai has been a key supplier for many Precision Machining, Rubber and Plastic Component parts. Striving to continue providing quality products at a competitive price, Jintai has taken innovative steps to ensure current machinery and production lines can maintain a competitive edge in such a highly competitive field.",
    geographicalAdvantage:
      "Geographical Advantage - Allowing us to provide Air and Ocean shipment solutions to wherever necessary.",
    getAQuote: "Get a Quote",
    globalOneStopManufacturingSolution:
      "Global one-stop manufacturing solution",
    home: "Home",
    howCanWeHelp: "How can we help?",
    investmentStrategy: "Investment Strategy - Jintai's Philosophy",
    jintai: "Jintai",
    jintaiDevelopment: "Jintai's Development",
    jintaiPhilosophy: "Jintai's Philosophy",
    jintaiProfile: "Jintai Profile",
    learnAboutJintai: "Learn About Jintai",
    learnMore: "Learn More",
    learnMoreAboutOurHistory: "Learn more about our history",
    machining: "Machining",
    materialList: "Material List",
    meilinIndustrialZone: "Meilin Industrial Zone",
    name: "Name",
    ningboJintai: "Ningbo Jintai",
    ningboZhejianChina: "Ningbo, Zhejiang, China",
    ofPartnershipsAndCustomers: "of Partnerships & Customers",
    officeContact: "Office Contact",
    oneStopSolution: "One Stop Solution",
    oneStopSolutionDescription:
      "Producing your design with our expert team through our Machining, Rubber, Plastic, Vacuum Brazing and Assembly solution.",
    oneStopSolutionText:
      "With our core competency in Precision Machining, Rubber Compression and Plastic Injection; we also have a strong supply chain to help our customers build and get what they need at Jintai. Whether it is Castings or brazing components; we will try our best to help provide a solution for our partners to help reduce their cost from over sourcing and managing multiple supply chains.",
    ourOneStopSolution: "Our One Stop Solution",
    ourOneStopSolutionText:
      "Producing your design with our expert team through our Machining, Rubber, Plastic, Vacuum Brazing and Assembly solution.",
    ourServices: "Our Services",
    partnersText:
      "Providing solutions for our long term partners across a wide range of market and industries.",
    phoneNumber: "Phone Number",
    plasticInjection: "Plastic Injection",
    plasticInjectionAndRubberCompression:
      "Plastic Injection & Rubber Compression",
    plasticInjectionMouldingAndRubberCompression:
      "Plastic Injection Moulding & Rubber Compression",
    plasticInjectionMouldingAndRubberCompressionDescription:
      "Using in-house Moulds built at Jintai, injecting molten material into a mould to form your design and product.",
    plasticInjectionText1:
      "Injection molding is a forming process using molds. Materials such as synthetic resins (plastics) are heated and melted, and then sent to the mold where they are cooled to form the designed shape",
    plasticInjectionText2:
      "We have vast experience working with a wide range of products using plastic injection from automotive to medical",
    plasticInjectionText3:
      "Depending on your requirements we can help to design the mould",
    precisionMachining: "Precision Machining",
    precisionMachiningAndAssembly: "Precision Machining & Assembly",
    precisionMachiningDescription:
      "Using CNC to product high-quality products ranging from steel to plastic products.",
    precisionMachiningExperience: "Precision Machining experience",
    precisionMachiningText1: "Precision Machining is our main competency",
    precisionMachiningText2:
      "We have over 100 machines ranging from 3 to 5 axis including Turning Machines, Screw Machines, 6 Axis Machines and Turning & Milling Integrated Machines",
    precisionMachiningText3:
      "We can handle parts with precision of up to 0.2μm",
    precisionMachiningText4:
      "We can source custom tools for complicated and unique products",
    profile: "Profile",
    qualityAndOnTime: "Quality & On Time",
    qualityAndOnTimeText:
      "With challenges to the global supply chain, Jintai aims to continue to shorten lead time and build quality products for our customers through rigorous inspection and detailed scheduling.",
    qualityAssurance: "Quality Assurance",
    qualtiyAssuranceText1:
      "Through our systematic approach to quality, we can ensure that the products we ship out are always within the drawing specifications",
    qualtiyAssuranceText2:
      "Our Quality Assurance systems include Co-ordinate measuring systems as well as Product Scanning Solutions to quickly and effectively measure parts",
    resources: "Resources",
    resourcesText:
      "To gain more insight into our company to the type of materials Jintai can immediately offer solutions to, please visit the resources page.",
    rubberAndPlasticCo: "Rubber & Plastic Co., Ltd",
    rubberAndPlastics: "Rubber & Plastics",
    rubberPlasticExperience: "Rubber & Plastic experience",
    sendUsAMessage: "Send Us a Message",
    services: "Services",
    submit: "Submit",
    vacuumBrazing: "Vacuum Brazing",
    vacuumBrazingDescription:
      "Clean, excellent bond strength and excellent temperature uniformity brazing solution.",
    valuablePartnersAndCustomers: "Valuable Partners & Customers",
    viewOtherResources: "View Other Resources",
    years: "years",
  },
  cn: {
    aboutJintai: "关于锦泰",
    aboutJintaiText:
      "20多年来，宁波锦泰是许多精密加工、橡胶和塑料零部件的主要供应商。锦泰努力继续以有竞争力的价格提供高质量的产品，并采取了创新措施，以确保现有的机械和生产线能够在竞争激烈的领域保持竞争优势。我们为全球许多世界知名的公司提供定制零部件，深入了解医疗、生命科学、汽车甚至金融工具等领域产品的多种不同生产方法。为了确保我们能够为所有领域提供高质量的零件，我们在IATF ISO 9001 16949和ISO 13485进行了认证。",
    aboutJintaiText2:
      "锦泰将很乐意成为您值得信赖的合作伙伴，为您量身定制解决方案。请务必让我们知道如何能帮助您。",
    aboutOurCompany: "关于我们公司",
    aboutUs: "关于我们",
    assembly: "装配",
    assemblyDescription:
      "结合我们所有的内部和外包解决方案，构建简单和复杂的装配产品",
    assemblyExperience: "组装经验",
    assemblyText1:
      "结合上述能力，我们为组装最终产品的所有方面提供一站式解决方案",
    assemblyText2: "我们能够满足客户指定的任何清洁或测试要求",
    assemblyText3:
      "产品在我们100K的洁净室中组装，以确保在出货前符合所有质量标准",
    brazing: "焊接",
    brazingText1:
      "我们提供广泛的钎焊解决方案，包括:激光焊接、TIG焊接和真空钎焊",
    brazingText2:
      "真空钎焊允许无泄漏钎焊，并允许不同材料焊接在一起，这是通过传统方法无法实现的",
    brazingText3: "由于其卓越的强度和光滑的表面，通常用于航空航天和医疗产品",
    casting: "铸件",
    castingSurfaceTreatmentSourcing: "铸造 / 表面处理 / 采购",
    castingSurfaceTreatmentSourcingDescription:
      "与许多知名供应商的密切联系使我们能够为客户提供一站式解决方案",
    castingText1: "我们非常重视了解客户的需求，以帮助降低供应链的相关成本。",
    castingText2:
      "我们拥有强大的铸件、表面处理和采购供应链，为我们的客户提供一站式解决方案。",
    company: "公司",
    companyLocation: "公司地理位置",
    companyProfile: "公司简介",
    contactInformation: "联系信息",
    contactUs: "联系我们",
    contactUsGeographicalAdvantage:
      "地理优势——靠近许多知名供应商，使我们能够为客户提供一站式解决方案.",
    continuousImprovement: "持续改进",
    continuousImprovementText:
      "跟上顶级的制造设备，我们可以继续制造更复杂的产品",
    customerFocused: "以客户为中心",
    customerFocusedText:
      "我们的专业团队将帮助提供DFM解决方案，以找到最具成本效益的方法来帮助实现您的设计",
    designForManufactureAnalysis: "面向制造分析的设计",
    designForManufactureAnalysisText:
      "根据您的设计，我们的专业团队可以帮助提供DFM，并帮助分析可以直接帮助最小化成本同时保持功能完整的区域，请随时联系我们",
    downloadCompanyProfile: "下载2022公司简介",
    downloadMaterialList: "下载2022材料清单",
    easyAccessToPorts: "到达主要的物流港口简单便捷",
    easyAndSimpleSolutionText:
      "一个简单易用的解决方案，帮助我们的客户实现设计。",
    email: "邮箱",
    emailAddress: "邮箱地址",
    fillInTheForm: "填写表格",
    fillInTheFormText: "填写表格，我们的负责人员将很快与您联系！",
    footerCopyright: "© 2020 宁波锦泰橡塑有限公司",
    footerText:
      "20多年以来，宁波锦泰一直是许多精密加工、橡胶和塑料零部件的主要供应商。为了继续以具有竞争力的价格提供高质量的产品，锦泰采取了创新措施，以确保现有的机械和生产线能够在竞争如此激烈的领域保持竞争优势。",
    fullAddress: "浙江省宁波市宁海县梅林开发区",
    geographicalAdvantage:
      "地理优势-允许我们在必要的地方提供空运和海运解决方案",
    getAQuote: "询价",
    globalOneStopManufacturingSolution: "全球一站式加工制造解决方案",
    home: "主页",
    howCanWeHelp: "我们怎样可以帮助您？",
    investmentStrategy: "Investment Strategy - Jintai's Philosophy",
    jintai: "锦泰",
    jintaiDevelopment: "锦泰的发展",
    jintaiPhilosophy: "锦泰的理念",
    jintaiProfile: "锦泰型材",
    learnAboutJintai: "了解锦泰",
    learnMore: "了解更多",
    learnMoreAboutOurHistory: "了解更多关于我们的发展",
    machining: "加工",
    materialList: "材料清单",
    meilinIndustrialZone: "梅林开发区",
    name: "姓名",
    ningboJintai: "宁波锦泰",
    ningboZhejianChina: "浙江省宁波市宁海县",
    ofPartnershipsAndCustomers: "的合作伙伴关系和客户",
    officeContact: "办公室联系人",
    oneStopSolution: "一站式解决方案",
    oneStopSolutionDescription:
      "通过我们的机械加工、橡胶、塑料、真空钎焊和装配解决方案，与我们的专业团队一起生产您的设计。",
    oneStopSolutionText:
      "凭借我们在精密加工、橡胶压缩和塑料注射方面的核心竞争力；我们也有一个强大的供应链来帮助我们的客户在锦泰建立和获得他们需要的产品。无论是铸件还是铜焊部件；我们将尽最大努力为我们的合作伙伴提供解决方案，帮助他们降低因过度采购和管理多个供应链而增加的成本。",
    ourOneStopSolution: "我们的一站式解决方案",
    ourOneStopSolutionText:
      "通过我们的机械加工、橡胶、塑料、真空钎焊和装配解决方案，与我们的专业团队一起生产您的设计产品",
    ourServices: "我们的服务",
    partnersText: "为我们在广泛市场和行业的长期合作伙伴提供解决方案",
    phoneNumber: "联系电话",
    plasticInjection: "注塑",
    plasticInjectionAndRubberCompression: "塑料注塑成型和橡胶压缩",
    plasticInjectionMouldingAndRubberCompression: "塑料注塑成型和橡胶压缩",
    plasticInjectionMouldingAndRubberCompressionDescription:
      "使用锦泰制造的内部模具，将熔化的材料注入模具，形成您的设计和产品",
    plasticInjectionText1:
      "注塑成型是一种使用模具的成型工艺。例如合成树脂(塑料)的材料是注塑成型，这是一种使用模具的成型工艺。诸如合成树脂(塑料)的材料被加热并熔化，然后被送到模具中冷却以形成设计的形状",
    plasticInjectionText2:
      "从汽车到医疗，我们在使用塑料注射的各种产品上积累了丰富的经验",
    plasticInjectionText3: "根据您的要求，我们可以帮助设计模具",
    precisionMachining: "精密加工",
    precisionMachiningAndAssembly: "精密加工和装配",
    precisionMachiningDescription: "使用CNC生产从钢材到塑料制品的高质量产品",
    precisionMachiningExperience: "精密加工经验",
    precisionMachiningText1: "精密加工是我们的主要生产能力",
    precisionMachiningText2:
      "我们现有100多台从3轴到5轴的机器，包括车床、螺丝车床、6轴机器和车铣一体机",
    precisionMachiningText3: "我们可以处理精度高达0.2μm的零件",
    precisionMachiningText4: "我们可以为复杂和独特的产品定制工具",
    profile: "简介",
    qualityAndOnTime: "质量和准时",
    qualityAndOnTimeText:
      "面对全球供应链的挑战，锦泰的目标是通过严格的检验和详细的计划，持续缩短交货时间，为我们的客户生产高质量的产品",
    qualityAssurance: "质量保证",
    qualtiyAssuranceText1:
      "通过我们系统的质量检测，我们可以确保我们出货的产品始终符合图纸规格",
    qualtiyAssuranceText2:
      "我们的质量保证体系包括坐标测量系统以及产品扫描解决方案，可快速有效地测量零件",
    resources: "资源",
    resourcesText:
      "需要更深入地了解我们公司能够立即提供解决方案的材料类型，请访问资源页面",
    rubberAndPlasticCo: "橡塑有限公司",
    rubberAndPlastics: "橡胶和塑料",
    rubberPlasticExperience: "橡胶制造经验",
    sendUsAMessage: "发送消息给我们",
    services: "关于我们",
    submit: "提交",
    vacuumBrazing: "真空焊接",
    vacuumBrazingDescription: "清洁、优异的结合强度和优异的温度均匀性钎焊溶液",
    valuablePartnersAndCustomers: "具有价值的合作伙伴和客户",
    viewOtherResources: "查看其它资源",
    years: "年",
  },
};

export { messages };
